import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState({ code: 'en', name: 'English', flag: 'https://flagcdn.com/w20/gb.png' });

    const languages = [
        { code: 'en', name: 'English', flag: 'https://flagcdn.com/w20/us.png', dir: 'ltr' },
        // { code: 'it', name: 'Italiano', flag: 'https://flagcdn.com/w20/it.png', dir: 'ltr' },
        { code: 'he', name: 'עברית', flag: 'https://flagcdn.com/w20/il.png', dir: 'rtl' },
    ];

    useEffect(() => {
        // Set the selected language and update direction based on the current language
        const currentLanguage = i18n.language;
        const defaultLanguage = languages.find((lang) => currentLanguage.startsWith(lang.code))
            || languages.find((lang) => lang.code === 'en');

        setSelected(defaultLanguage);
        document.documentElement.dir = defaultLanguage.dir;
    }, [i18n.language]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const changeLanguage = (item) => {
        i18n.changeLanguage(item.code);
        setSelected(item);
        document.documentElement.dir = item.dir;
        setIsOpen(false);
    };

    return (
        <div className="language-selector">
            <div className="flag-circle" onClick={toggleDropdown}>
                <span className="flag"><img src={selected.flag} alt={`${selected.name} flag`} /></span>
            </div>
            {isOpen && (
                <div className="dropdown-menu" style={{ position: 'absolute', zIndex: 9999 }}>
                    {languages.map((item) => (
                        <button key={item.code} className="dropdown-item" onClick={() => changeLanguage(item)}>
                            <span style={{ width: 30 }}><img src={item.flag} alt={`${item.name} flag`} /></span> {item.name}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LanguageSelector;
