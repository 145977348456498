import React from "react";
import i18n from "../../i18n";


export default function MockupSection() {

    const isHebrew = i18n.language.startsWith('he');

    const screens = [
        {
            he: require("../../../src/assets/mockups/home_he.jpg"),
            en: require("../../../src/assets/mockups/home_en.jpg"),
        },
        {
            he: require("../../../src/assets/mockups/managment_he.jpg"),
            en: require("../../../src/assets/mockups/managment_en.jpg")
        },
        {
            he: require("../../../src/assets/mockups/revenue_he.jpg"),
            en: require("../../../src/assets/mockups/revenue_en.jpg")
        },
        {
            he: require("../../../src/assets/mockups/chat_he.jpg"),
            en: require("../../../src/assets/mockups/chat_en.jpg")
        }
    ]

    return (
        <section className="bg-gray-50 py-12">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center">
                    <h2 className="p-2 text-4xl font-bold bg-gradient-to-r from-brand-navy to-brand-teal bg-clip-text text-transparent">
                        {i18n.t('communities.mockup.title')}
                    </h2>
                </div>
                <p className="text-center text-gray-600 mb-12">
                    {i18n.t('communities.mockup.description')}
                </p>

                {/* Horizontal Scrollable Container */}
                <div className="relative">
                    <div
                        className="flex overflow-x-auto no-scrollbar px-2 rtl:space-x-reverse"
                        style={{ gap: "1.5rem", padding: 15 }} // Consistent spacing for both LTR and RTL
                    >
                        {/* Mockup Cards */}
                        {screens.map((screen, index) => (
                            <div
                                key={index}
                                className="flex-shrink-0 w-64 h-auto bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300"
                            >
                                <img
                                    src={isHebrew ? screen.he : screen.en}
                                    alt={`Mockup ${index + 1}`}
                                    className="rounded-2xl w-full h-auto"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
